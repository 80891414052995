@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  padding: 0;
  margin: 0;
  background-color: #0f111a;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


.brand-name {
  font-family: 'Kiona';
  font-weight: 800;
  font-style: normal;
}
